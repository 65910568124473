/** @jsx jsx */
import { graphql } from "gatsby"
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"

import Blocks from "../block-library/components/blocks"
import SEODetails from "../block-library/components/seo-details"
import ContentCta from "../block-library/content-cta"
import Hero from "../block-library/hero"
import Highlights from "../block-library/highlights"
import MirroredHighlights from "../block-library/mirrored-highlights"
import Reviews from "../block-library/reviews"
import ShowcaseProductItem from "../block-library/showcase-product-item"
import Layout from "../components/layout"

const OurProductsPage = ({
  data: {
    strapi: { ourProductsPage },
  },
}) => (
  <Layout title={ourProductsPage.title}>
    <SEODetails title={ourProductsPage.title} {...ourProductsPage.seoDetails} />

    {ourProductsPage.blocks?.length ? (
      <Blocks blocks={ourProductsPage.blocks} />
    ) : (
      <Fragment>
        <Hero {...ourProductsPage.hero} />
        {ourProductsPage.products.map((product, index) => (
          <ShowcaseProductItem key={index} {...product} />
        ))}
        <ContentCta {...ourProductsPage.contentCta} />
        <Highlights {...ourProductsPage.highlights} />
        <MirroredHighlights {...ourProductsPage.mirroredHighlights} />
        <Reviews {...ourProductsPage.reviews} />
      </Fragment>
    )}
  </Layout>
)

export const query = graphql`
  {
    strapi {
      ourProductsPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        hero {
          ...HeroFragment
        }
        products {
          ...ShowcaseProductItemFragment
        }
        contentCta {
          ...ContentCtaFragment
        }
        highlights {
          ...HighlightsFragment
        }
        mirroredHighlights {
          ...MirroredHighlightsFragment
        }
        reviews {
          ...ReviewsFragment
        }
        blocks {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksBannerUsp {
            ...BannerUspFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
        }
      }
    }
  }
`

export default OurProductsPage
